import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { getHealthCheck, getSyncStatus, getSyncStats, startSync, stopSync } from './api';
import './SyncEngine.css';

export default function SyncEngine() {
  const [cookies] = useCookies(['access_token']);
  const [isHealthy, setIsHealthy] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const [syncStats, setSyncStats] = useState(null);

  // Get health check
  useEffect(async () => {
    const isHealthy = await getHealthCheck(cookies);
    setIsHealthy(isHealthy);
    const syncStatus = await getSyncStatus(cookies);
    setSyncStatus(syncStatus);
    const syncStats = await getSyncStats(cookies);
    setSyncStats(syncStats);
  }, []);

  const getHeaders = () => {
    if (syncStats.length === 0) return [];
    return Object.keys(syncStats[0]);
  };

  function isISO8601Timestamp(str) {
    // Regex for the exact format YYYY-MM-DDThh:mm:ssZ
    const exactISOPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

    // Test if the string matches the pattern
    return exactISOPattern.test(str);
  }

  // Format cell value based on its type
  const formatValue = (value) => {
    if (value === null) return 'null';
    if (value === undefined) return 'undefined';
    if (typeof value === 'boolean') return value.toString();
    if (typeof value === 'object') return JSON.stringify(value);
    if (typeof value === 'string' && isISO8601Timestamp(value)) {
      return new Date(value).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
      });
    }
    return value;
  };

  return (
    <div className='SyncEngine'>
      {/* Display health status */}
      <div className='SyncEngine__health'>
        <h1>Sync Engine Master Health</h1>
        <p>{isHealthy ? 'Healthy' : 'Unhealthy'}</p>
      </div>

      {/* Display sync status */}
      <div className='SyncEngine__status'>
        <h1>Sync Status</h1>
        <pre>{JSON.stringify(syncStatus, null, 4)}</pre>
      </div>

      {/* Display sync stats */}
      <div className='SyncEngine__stats'>
        <h1>Sync Stats</h1>
        {syncStats && syncStats.length > 0 ? (
          <div className='overflow-x-auto max-h-96 overflow-y-auto'>
            <table className='min-w-full border-collapse border border-gray-300 text-xs'>
              <thead className='sticky top-0 z-10'>
                <tr className='bg-gray-100'>
                  {getHeaders().map((header, index) => (
                    <th key={index} className='border border-gray-300 p-2 text-left'>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {syncStats &&
                  syncStats.map((row, rowIndex) => (
                    <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      {getHeaders().map((header, colIndex) => (
                        <td key={colIndex} className='border border-gray-300 p-2'>
                          {formatValue(row[header])}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No data to display.</p>
        )}
      </div>
    </div>
  );
}
