import config from '../../config.js';
import axios from 'axios';

const getHealthCheck = async (cookies) => {
  console.log('getHealthCheck');
  const url = `${config.backendUrl}/sync-engine`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const res = await axios.get(url, axiosConfig);
  console.log(res.data);

  if (res.data.statusCode === 200) {
    return true;
  }

  return false;
};

const getSyncStatus = async (cookies) => {
  const url = `${config.backendUrl}/sync-engine/status`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const res = await axios.get(url, axiosConfig);
  return res.data.data;
};

const getSyncStats = async (cookies) => {
  const url = `${config.backendUrl}/sync-engine/stats`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const res = await axios.get(url, axiosConfig);
  return res.data.data;
};

const startSync = async (cookies, config) => {
  const url = `${config.backendUrl}/sync-engine/start`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const res = await axios.post(url, config, axiosConfig);
  return res.data.data;
};

const stopSync = async (cookies) => {
  const url = `${config.backendUrl}/sync-engine/stop`;
  const axiosConfig = {
    headers: { Authorization: `Bearer ${cookies.access_token}` },
  };
  const res = await axios.delete(url, axiosConfig);
  return res.data.data;
};

export { getHealthCheck, getSyncStatus, getSyncStats, startSync, stopSync };
